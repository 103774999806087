.welcome-page-container-static {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the top */
  height: 100vh;
  padding: 20px; /* Add some padding for spacing */
  box-sizing: border-box; /* Include padding in the height calculation */
}

.welcome-logo-container {
  margin-bottom: 20px;
}

.welcome-logo {
  width: 100px;
  border-radius: 30px;
}

.welcome-description-container {
  text-align: center;
  margin-bottom: 20px; /* Adjust margin for spacing */
  color: white;
}

.welcome-description-container-static h2 {
  font-weight: 700; /* Increase boldness */
  color: black; /* Set title color to black */
  font-size: 28px; /* Increase font size */
  margin-bottom: 20px; /* Add some space below the title */
  text-align: center;
}

.privacy-policy-container {
  width: 100%; 
  max-width: 800px; 
  background-color: white; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333; 
  margin-bottom: 20px;
}


.welcome-login-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.welcome-login-button:hover {
  background-color: #0056b3;
}

.cms-txt-ui{
  overflow: visible;
  white-space: pre-line;
  padding: 0;
  font-family: inherit;
}