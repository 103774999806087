.welcome-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.welcome-logo-container {
  margin-bottom: 20px;
}

.welcome-logo {
  width: 150px; /* Adjust the size of the logo */
  border-radius: 30px; /* Add curves to the logo (rounded corners) */
}

.welcome-description-container {
  text-align: center;
  margin-bottom: 30px;
  color: white; /* Make the text white */
}

.welcome-description-container h2 {
  font-weight: bold;
  color: white; /* Make the heading white */
}

.welcome-login-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
}

.welcome-login-button:hover {
  background-color: #0056b3;
}
