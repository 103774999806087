/* ContactForm.css */

/* body {
    background-color: #25274d;
  } */
  .contact-form-wrap{
    height: 100vh;
    position: relative;
    background-color: #e9e9e9;
  }
  .contact-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 94%;
    max-width: 1000px;
  }
  
  .contact {
    padding: 0;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Soft shadow effect for 3D appearance */
  }
  

  
  .contact-info {
    padding: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }
  
  .contact-info img {
    margin-bottom: 13px;
  }
  
  .contact-info h2 {
    margin-bottom: 10%;
  }
  
  .contact-form{
    background: #fff;
    padding: 20px;
    width: 67%;
  }
  .contact-form label {
    font-weight: 600;
    margin-bottom: 10px;
    color: #000;
  }
  
  .contact-form button {
    background: #25274d;
    color: #fff;
    font-weight: 600;
    width: 100%;
    border: 1px solid #25274d !important;
  }
  .contact-form button:hover{
    background: transparent;
    color: #25274d;
  }
  
  .contact-form button:focus {
    box-shadow: none;
  }
  .form-group{
    margin-bottom: 20px;
  }

  .contact-form-row{
    display: flex;
    border-radius: 20px;
    border: 1px solid #28234d;
    overflow: hidden;
  }

  .contact-form-col-left{
    width: 33%;
  }

  .image-main{
    width: 5rem;
  }

  @media(max-width: 767px){
    .contact-form-row{
        display: block;
    }
    .contact-form-col-left , .contact-form{
        width: 100%;
    }
  }
  .welcome-logo {
    width: 150px; /* Adjust the size of the logo */
    border-radius: 30px; /* Add curves to the logo (rounded corners) */
  }