@import '~antd/es/style/themes/default.less';

.wrapperClassName{
	background:lighten(@border-color-base,35%);
	padding:5px 10px 10px;
	border-radius:4px;
	border:1px solid @border-color-base;
}
.toolbarStyle{
	background:lighten(@border-color-base,35%);
	border:0; padding:6px 0 0;
	margin:0 !important;
	
}
.mainEditorStyle{
	background:lighten(@border-color-base,25%); min-height:200px; border-radius:4px;
	border:1px solid lighten(@border-color-base,4%);
}
.rdw-editor-wrapper img{width:auto}
.rdw-inline-wrapper, .rdw-link-wrapper, .rdw-link-disabled, .rdw-text-align-wrapper, .rdw-list-wrapper, .rdw-option-wrapper, .rdw-option-disabled{
		//background: #fff; border:1px solid @border-color-base; border-radius:4px;
		.rdw-option-wrapper, .rdw-dropdown-selectedtext{ min-width: 35px; height: 35px; margin:0 2px;}
	}
	.rdw-dropdown-wrapper{
		height: 35px;
		.rdw-dropdown-selectedtext{ min-width: 35px; height: 35px; margin:0 2px;}
	}
	.rdw-editor-main{padding:0 15px}
@primary-color: rgba(252, 184, 0, 1);@link-color: rgba(252, 184, 0, 1);@error-color: #f14705;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@input-hover-border-color: #d9d9d9;@success-color: #690;@warning-color: rgba(252, 184, 0, 1);@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .75);@text-color-secondary: rgba(0, 0, 0, .45);@disabled-color: rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;